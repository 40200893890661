import store from '@/store'
import i18n from '@/libs/i18n'
import incomingInvoice from '../../../store/incomingInvoice/incomingInvoice'

export const useIncomingInvoices = () => {

  const submitValidatedIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      if ('id' in incomingInvoice && incomingInvoice.id != null) {
        updateIncomingInvoice(incomingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewIncomingInvoice(incomingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice/addIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice/updateIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeIncomingInvoice = (incomingInvoiceDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [incomingInvoiceDocument, 'incomingInvoice'])
        .then(response => {
          if (response.data.paymentMethod) {
            response.data.paymentMethod.name = i18n.t(response.data.paymentMethod.name)
          }
          if (response.data.paymentTerm) {
            response.data.paymentTerm.term = i18n.t(response.data.paymentTerm.term)
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice/removeIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadIncomingInvoice = (incomingInvoice) => {
    if (incomingInvoice.receipt != null) {
      let link = document.createElement('a')
      link.href = incomingInvoice.receipt.url
      link.target = "_blank"
      link.download = incomingInvoice.receipt.name + '.' + incomingInvoice.receipt.extension
      link.click()
    }
  }

  const getIncomingInvoiceStatus = (incomingInvoice) => {
    if (incomingInvoice._filters.some(flt => flt.key == '_filter_isWaitingForPayment' && flt.value == true)) {
      return 'waitingForPayment'
    // } else if (incomingInvoice._filters.some(flt => flt.key == '_filter_isValidatedStatus' && flt.value == true)) {
    //   return 'sent'
    // } else {
    //   return 'draft'
    }
  }

  const getIncomingInvoiceIconStatus = (incomingInvoice) => {
    if (getIncomingInvoiceStatus(incomingInvoice) == 'waitingForPayment') return { variant: 'secondary', icon: 'hourglass', library: 'far' }
    // else if (getIncomingInvoiceStatus(incomingInvoice) == 'invoiced') return {
    //   variant: 'success',
    //   icon: 'file-invoice-dollar'
    // }
    // else return { variant: 'primary', icon: 'paper-plane' }
  }

  const getIncomingInvoiceIconType = (incomingInvoice) => {
    if (incomingInvoice.type == 'toll') return { variant: 'secondary', icon: 'car-side' }
    else if (incomingInvoice.type == 'catering') return { variant: 'secondary', icon: 'utensils' }
    else if (incomingInvoice.type == 'accommodation') return { variant: 'secondary', icon: 'suitcase-rolling' }
    else if (incomingInvoice.type == 'production') return { variant: 'secondary', icon: 'tools' }
    else return { variant: 'secondary', icon: 'wallet' }
  }

  return {
    submitValidatedIncomingInvoice,
    removeIncomingInvoice,
    recognizeIncomingInvoice,
    downloadIncomingInvoice,
    getIncomingInvoiceStatus,
    getIncomingInvoiceIconStatus,
    getIncomingInvoiceIconType
  }
}