<template>
  <generic-content
      :isDownloadable="true"
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      @click="isParentMethodPriority?$emit('click'):download()"
      @download="download"
      @toRoute="isParentMethodPriority?$emit('toRoute'):''"
      @edit="$emit('edit')"
      @pay="$emit('pay')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          :variant="'light-'+iconStatus.variant"
          class="my-auto"
      >
        <icon
            :icon="iconStatus.icon"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate">
        {{ incomingInvoice.supplierCompany ? incomingInvoice.supplierCompany._display : '-' }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small>{{ incomingInvoice.billingDate | moment('L') }}</small>
        <small>{{ currency(incomingInvoice.preTaxAmount) }}</small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '@/utils/filter'
import { useIncomingInvoices } from '@/views/purchases/incomingInvoices/useIncomingInvoices'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => { }
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconStatus = computed(() => {
      return getIncomingInvoiceIconStatus(props.incomingInvoice)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getIncomingInvoiceIconStatus } = useIncomingInvoices()

    const download = () => {
      console.log(props.incomingInvoice.receipt.url)
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = props.incomingInvoice.receipt.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      iconStatus,

      // Methods
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>