<template>
  <b-form-group
      :label-for="name"
      :label="displayLabel?capitalize($tc(name)):''"
  >
    <validation-provider
        v-if="isRequired"
        #default="{ errors }"
        :name="$tc(name)"
        rules="required"
    >
      <quill-editor
          v-if="withHTML"
          :id="name"
          v-model="localModel"
          :options="editorOptions"
          :disabled="disabled"
          :class="{'disabled': disabled}"
      />
      <b-form-textarea
          v-else
          :id="name"
          :placeholder="placeholder"
          rows="3"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>


    <quill-editor
        v-else-if="withHTML"
        :id="name"
        v-model="localModel"
        :options="editorOptions"
        :disabled="disabled"
        :class="{'disabled': disabled}"
    />
    <b-form-textarea
        v-else
        :id="name"
        :placeholder="placeholder"
        rows="3"
    />

  </b-form-group>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider } from 'vee-validate'
import { required } from '@/utils/validations/validations'

import i18n from '@/libs/i18n'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    withHTML: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const editorOptions = ref({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
      },
      placeholder: props.placeholder != '' ? capitalize(i18n.tc(props.placeholder)) : (i18n.t('YourMessage')),
    })
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      required,

      // Data
      editorOptions,
      localModel,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.quill-editor {
  &.disabled, .ql-disabled {
    background-color: #efefef;
  }
}
</style>