<template>
  <b-card
      no-body
      class="overflow-hidden cursor-pointer"
      @mouseover="isHovered=true"
      @mouseleave="isHovered=false"
      @click="$emit('click')"
  >
    <slot v-if="byContent"
          name="content"/>

    <div v-else
         class="d-flex justify-content-between">
      <div class="m-auto px-1">
        <slot name="iconContent"/>
      </div>

      <div
          class="w-100 py-50 text-truncate"
          :class="{'pr-1': !isHovered}"
      >
        <slot name="bodyContent"/>
      </div>
      <div
          v-if="isHovered"
          class="m-auto px-1"
      >
        <slot name="actionContent">
          <button-delete
              @click.native.stop="allowDelete?$emit('delete'):''"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              :disabled="!allowDelete"
          />
        </slot>

        <!--        <icon icon="times"-->
        <!--              :disabled="!allowDelete"-->
        <!--              class="text-danger"/>-->
      </div>

    </div>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import ButtonDelete from '@/components/button/Delete'

export default {
  components: {
    ButtonDelete
  },
  props: {
    byContent: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>