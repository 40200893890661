<template>
  <div>

    <!-- Payment form-->
    <b-row v-if="containerSize=='sm'">

      <!-- Wording -->
      <b-col md="8">
        <field-input
            name="wording"
            rules="required"
            :model.sync="bankOperationLocal.wording"
        />
      </b-col>

      <!-- Amount -->
      <b-col md="4">
        <field-input
            name="amount"
            rules="required|decimal"
            :model.sync="amount"
            :class="amount>0?'text-success':'text-danger'"
        />
      </b-col>

      <!-- Operation date -->
      <b-col md="6">
        <field-date
            name="operationDate"
            :isRequired="true"
            :model.sync="bankOperationLocal.operationDate"
        />
      </b-col>

      <!-- Transaction date -->
      <b-col md="6">
        <field-date
            name="bankTransactionDate"
            :isRequired="true"
            :model.sync="bankOperationLocal.bankTransactionDate"
        />
      </b-col>

      <!-- Transaction method -->
      <b-col md="6">
        <field-select
            :model.sync="bankOperationLocal.paymentMethod"
            name="paymentMethod"
            :options="paymentMethods"
            :isRequired="true"
            label="name"
        />
      </b-col>

      <!-- Bank account -->
      <b-col md="6">
        <field-select
            :model.sync="bankOperationLocal.bankAccount"
            name="bankAccount"
            :options="bankAccounts"
            :isRequired="true"
            label="_display"
        />
      </b-col>

      <!-- Incoming invoices -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <field-select
            :model.sync="bankOperationLocal.incomingInvoices"
            name="expenses"
            :multiple="true"
            :options="incomingInvoicesNotCompletelyPaid"
            label="billNumber"
            :placeholder="$t('ChooseExpense')"
        />
      </b-col>

      <!-- Outgoing invoices -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <field-select
            :model.sync="bankOperationLocal.outgoingInvoices"
            name="invoices"
            :multiple="true"
            :options="outgoingInvoicesNotCompletelyPaid"
            label="_display"
            :placeholder="$t('ChooseInvoice')"
        />
      </b-col>

      <!-- Comments -->
      <b-col md="12">
        <field-textarea
            name="comment"
            :model.sync="bankOperationLocal.comments"
            :withHTML="false"
        />
      </b-col>


    </b-row>

    <!-- Bank operation form-->
    <b-row v-else-if="containerSize=='md'">

      <!-- Operation date -->
      <b-col md="2">
        <field-date
            name="operationDate"
            :isRequired="true"
            :model.sync="bankOperationLocal.operationDate"
            :listIndex="index"
        />
      </b-col>

      <!-- Transaction date -->
      <b-col md="2">
        <field-date
            name="bankTransactionDate"
            :model.sync="bankOperationLocal.bankTransactionDate"
            :listIndex="index"
        />
      </b-col>

      <!-- Wording -->
      <b-col md="5">
        <field-input
            name="wording"
            rules="required"
            :model.sync="bankOperationLocal.wording"
            :listIndex="index"
        />
      </b-col>

      <!-- Amount -->
      <b-col md="3"
             class="d-flex justify-content-between">
        <field-input
            name="amount"
            rules="required|decimal"
            :model.sync="amount"
            :class="amount>0?'text-success':'text-danger'"
            :listIndex="index"
            @change="$emit('update')"
        />

        <div class="mt-2">
          <button-remove
              :withText="false"
              :withIcon="true"
              class="mt-50 ml-2"
              @click="$emit('removeBankOperation')"/>
        </div>
      </b-col>


    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldInput from '@/components/input/Input'
import FieldDate from '@/components/input/Date'
import FieldSelect from '@/components/input/Select'
import FieldTextarea from '@/components/input/Textarea'
import ButtonRemove from '@/components/button/Remove'
import useAPI from '../../utils/useAPI'
import store from '@/store'

export default {
  components: {
    FieldInput,
    FieldDate,
    FieldSelect,
    FieldTextarea,
    ButtonRemove
  },
  props: {
    bankOperation: {
      type: Object,
      default: () => {}
    },
    containerSize: {
      type: String,
      default: 'sm'
    },
    index: {
      type: Number,
      default: 0
    },
    showBankAffiliation: {
      type: Boolean,
      default: false
    },
    sumOwed: {
      default: null
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const bankOperationLocal = ref(props.bankOperation)
    const amount = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccounts, paymentMethods } = useAPI()

    const incomingInvoicesNotCompletelyPaid = computed(() => {
      return store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']
    })

    const outgoingInvoicesNotCompletelyPaid = computed(() => {
      return store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(bankOperationLocal, val => {
      emit('update', val)
    })

    watch(amount, val => {
      if (val >= 0) {
        bankOperationLocal.value.debit = null
        bankOperationLocal.value.credit = parseFloat(val)
        bankOperationLocal.value._balance = parseFloat(val)
      } else {
        bankOperationLocal.value.debit = -parseFloat(val)
        bankOperationLocal.value.credit = null
        bankOperationLocal.value._balance = parseFloat(val)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.sumOwed != null) {
      amount.value = props.sumOwed
    } else {
      if (props.bankOperation.debit == null || props.bankOperation.credit == null) {
        if (props.bankOperation.debit != null) {
          amount.value = -props.bankOperation.debit
        } else if (props.bankOperation.credit != null) {
          amount.value = props.bankOperation.credit
        }
      } else {
        if (props.bankOperation.credit != 0) {
          amount.value = props.bankOperation.credit
          // console.log(JSON.parse(JSON.stringify(props.bankOperation.credit)))
        } else {
          amount.value = -props.bankOperation.debit
          // console.log(JSON.parse(JSON.stringify(-props.bankOperation.debit)))
        }
      }
    }

    return {
      // Components

      // Data
      bankOperationLocal,
      amount,

      // Computed
      paymentMethods,
      bankAccounts,
      incomingInvoicesNotCompletelyPaid,
      outgoingInvoicesNotCompletelyPaid,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>